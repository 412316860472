import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, inject, Directive } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { tuiWatch } from '@taiga-ui/cdk/observables';
import { TUI_IS_MOBILE } from '@taiga-ui/cdk/tokens';
import { tuiWithStyles } from '@taiga-ui/cdk/utils/miscellaneous';
import { TuiTextfieldComponent } from '@taiga-ui/core/components/textfield';
import * as i1 from '@taiga-ui/core/directives/appearance';
import { tuiAppearanceState, tuiAppearanceOptionsProvider, TuiWithAppearance } from '@taiga-ui/core/directives/appearance';
import * as i2 from '@taiga-ui/core/directives/hint';
import { TuiHintDescribe, TuiHintHover, TUI_HINT_OPTIONS, TuiHintDirective } from '@taiga-ui/core/directives/hint';
import { TUI_ICON_START } from '@taiga-ui/core/tokens';
import { map } from 'rxjs';
import { tuiCreateOptions } from '@taiga-ui/cdk/utils/di';
const [TUI_TOOLTIP_OPTIONS, tuiTooltipOptionsProvider] = tuiCreateOptions({
  icon: '',
  appearance: 'icon'
});
class TuiTooltipStyles {
  static {
    this.ɵfac = function TuiTooltipStyles_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTooltipStyles)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiTooltipStyles,
      selectors: [["ng-component"]],
      hostAttrs: [1, "tui-tooltip"],
      decls: 0,
      vars: 0,
      template: function TuiTooltipStyles_Template(rf, ctx) {},
      styles: ["[tuiTooltip]{border-width:.125rem;border-radius:100%;cursor:pointer;pointer-events:auto;background-clip:content-box!important}[tuiTooltip] [tuiBlock],[tuiTooltip] [tuiCell][data-size=s],[tuiLabel][data-orientation=horizontal] [tuiTooltip]{border-width:.25rem}[tuiTitle] [tuiTooltip]{font-size:1rem;border:none}[tuiTooltip]:after{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);inline-size:1rem;block-size:1rem}@media (hover: hover) and (pointer: fine){tui-textfield:hover [tuiTooltip][data-appearance=icon]:after,tui-textarea:hover [tuiTooltip][data-appearance=icon]:after,tui-primitive-textfield:hover [tuiTooltip][data-appearance=icon]:after,tui-input-tag:hover [tuiTooltip][data-appearance=icon]:after{color:var(--tui-text-secondary)}}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipStyles, [{
    type: Component,
    args: [{
      standalone: true,
      template: '',
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        class: 'tui-tooltip'
      },
      styles: ["[tuiTooltip]{border-width:.125rem;border-radius:100%;cursor:pointer;pointer-events:auto;background-clip:content-box!important}[tuiTooltip] [tuiBlock],[tuiTooltip] [tuiCell][data-size=s],[tuiLabel][data-orientation=horizontal] [tuiTooltip]{border-width:.25rem}[tuiTitle] [tuiTooltip]{font-size:1rem;border:none}[tuiTooltip]:after{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);inline-size:1rem;block-size:1rem}@media (hover: hover) and (pointer: fine){tui-textfield:hover [tuiTooltip][data-appearance=icon]:after,tui-textarea:hover [tuiTooltip][data-appearance=icon]:after,tui-primitive-textfield:hover [tuiTooltip][data-appearance=icon]:after,tui-input-tag:hover [tuiTooltip][data-appearance=icon]:after{color:var(--tui-text-secondary)}}\n"]
    }]
  }], null, null);
})();
class TuiTooltip {
  constructor() {
    this.textfield = inject(TuiTextfieldComponent, {
      optional: true
    });
    this.isMobile = inject(TUI_IS_MOBILE);
    this.describe = inject(TuiHintDescribe);
    this.driver = inject(TuiHintHover);
    this.nothing = tuiWithStyles(TuiTooltipStyles);
    this.state = tuiAppearanceState(toSignal(inject(TuiHintHover).pipe(map(hover => hover ? 'hover' : null), tuiWatch()), {
      initialValue: null
    }));
  }
  ngDoCheck() {
    if (this.textfield?.id) {
      this.describe.tuiHintDescribe = this.textfield.id;
    }
  }
  onClick(event) {
    if (this.isMobile) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.driver.toggle();
    }
  }
  static {
    this.ɵfac = function TuiTooltip_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTooltip)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTooltip,
      selectors: [["tui-icon", "tuiTooltip", ""]],
      hostAttrs: ["tuiTooltip", ""],
      hostBindings: function TuiTooltip_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click.prevent", function TuiTooltip_click_prevent_HostBindingHandler() {
            return 0;
          })("mousedown", function TuiTooltip_mousedown_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([tuiAppearanceOptionsProvider(TUI_TOOLTIP_OPTIONS), {
        provide: TUI_ICON_START,
        useFactory: () => inject(TUI_TOOLTIP_OPTIONS).icon || inject(TUI_HINT_OPTIONS).icon
      }]), i0.ɵɵHostDirectivesFeature([i1.TuiWithAppearance, {
        directive: i2.TuiHintDescribe,
        inputs: ["tuiHintDescribe", "tuiTooltipDescribe"]
      }, {
        directive: i2.TuiHintDirective,
        inputs: ["tuiHint", "tuiTooltip", "tuiHintAppearance", "tuiHintAppearance", "tuiHintContext", "tuiHintContext"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltip, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: 'tui-icon[tuiTooltip]',
      providers: [tuiAppearanceOptionsProvider(TUI_TOOLTIP_OPTIONS), {
        provide: TUI_ICON_START,
        useFactory: () => inject(TUI_TOOLTIP_OPTIONS).icon || inject(TUI_HINT_OPTIONS).icon
      }],
      hostDirectives: [TuiWithAppearance, {
        directive: TuiHintDescribe,
        inputs: ['tuiHintDescribe: tuiTooltipDescribe']
      }, {
        directive: TuiHintDirective,
        inputs: ['tuiHint: tuiTooltip', 'tuiHintAppearance', 'tuiHintContext']
      }],
      host: {
        tuiTooltip: '',
        '(click.prevent)': '0',
        '(mousedown)': 'onClick($event)'
      }
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_TOOLTIP_OPTIONS, TuiTooltip, tuiTooltipOptionsProvider };
