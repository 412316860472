import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
@Injectable()
export class CustomOverlayContainer extends OverlayContainer {
  override _createContainer(): void {
    let container = document.querySelector('.custom-overlay-container');
    if (!container) {
      container = document.body;
    }

    const overlayContainer = this._document.createElement('div');
    overlayContainer.classList.add('cdk-overlay-container');
    container.appendChild(overlayContainer);
    this._containerElement = overlayContainer;
  }
}
