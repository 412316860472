import { TuiRoot } from "@taiga-ui/core";
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faHome, faUser, faCoffee, faChevronRight, faXmark, faArrowRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxSonnerToaster } from "ngx-sonner";
@Component({
    selector: 'app-root',
    imports: [RouterOutlet, TuiRoot, NgxSonnerToaster],
    templateUrl: './app.component.html',
    styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'app';

  private readonly library: FaIconLibrary = inject(FaIconLibrary);

  constructor() {
    this.library.addIcons(faHome, faUser, faCoffee, faChevronRight,faTimesCircle, faXmark,faArrowRight);
  }
}
