import * as i0 from '@angular/core';
import { Pipe } from '@angular/core';
class TuiMapperPipe {
  /**
   * Maps object to an arbitrary result through a mapper function
   *
   * @param value an item to transform
   * @param mapper a mapping function
   * @param args arbitrary number of additional arguments
   */
  transform(value, mapper, ...args) {
    return mapper(value, ...args);
  }
  static {
    this.ɵfac = function TuiMapperPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiMapperPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "tuiMapper",
      type: TuiMapperPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMapperPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'tuiMapper'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiMapperPipe };
