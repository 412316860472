import { tuiCreateToken, tuiProvideOptions, tuiCreateTokenFromFactory, tuiProvide } from '@taiga-ui/cdk/utils/miscellaneous';
import * as i0 from '@angular/core';
import { Directive, Input, NgModule, ChangeDetectorRef } from '@angular/core';
import { AbstractTuiController } from '@taiga-ui/legacy/classes';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tuiWatch } from '@taiga-ui/cdk/observables';
import { TUI_TEXTFIELD_APPEARANCE } from '@taiga-ui/legacy/tokens';
import { merge, NEVER } from 'rxjs';
class TuiTextfieldController {
  constructor(change$, options, legacyAppearance, appearanceDirective, cleanerDirective, customContentDirective, iconDirective, iconLeftDirective, labelOutsideDirective, sizeDirective, prefixDirective, postfixDirective, fillerDirective) {
    this.change$ = change$;
    this.options = options;
    this.legacyAppearance = legacyAppearance;
    this.appearanceDirective = appearanceDirective;
    this.cleanerDirective = cleanerDirective;
    this.customContentDirective = customContentDirective;
    this.iconDirective = iconDirective;
    this.iconLeftDirective = iconLeftDirective;
    this.labelOutsideDirective = labelOutsideDirective;
    this.sizeDirective = sizeDirective;
    this.prefixDirective = prefixDirective;
    this.postfixDirective = postfixDirective;
    this.fillerDirective = fillerDirective;
  }
  get appearance() {
    return this.appearanceDirective.appearance || this.legacyAppearance;
  }
  get cleaner() {
    return this.cleanerDirective.cleaner;
  }
  get customContent() {
    return this.customContentDirective.customContent || '';
  }
  get icon() {
    return this.iconDirective.icon;
  }
  get iconStart() {
    return this.iconLeftDirective.iconStart;
  }
  get labelOutside() {
    return this.labelOutsideDirective.labelOutside;
  }
  get size() {
    return this.sizeDirective.size;
  }
  get prefix() {
    return this.prefixDirective.prefix;
  }
  get postfix() {
    return this.postfixDirective.postfix;
  }
  get filler() {
    return this.fillerDirective.filler;
  }
}

/**
 * @deprecated: drop in v5.0
 * Default values for primitive textfield options
 */
const TUI_TEXTFIELD_DEFAULT_OPTIONS = {
  iconCleaner: '@tui.x',
  hintOnDisabled: false
};
/**
 * @deprecated: drop in v5.0
 * Default parameters for textfield
 */
const TUI_TEXTFIELD_OPTIONS = tuiCreateToken(TUI_TEXTFIELD_DEFAULT_OPTIONS);
function tuiTextfieldOptionsProvider(options) {
  return tuiProvideOptions(TUI_TEXTFIELD_OPTIONS, options, TUI_TEXTFIELD_DEFAULT_OPTIONS);
}
const TUI_TEXTFIELD_APPEARANCE_DIRECTIVE = tuiCreateTokenFromFactory(() => new TuiTextfieldAppearanceDirective());
class TuiTextfieldAppearanceDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    // it's an empty string by default for backward compatibility
    // (see comment https://github.com/taiga-family/taiga-ui/pull/3007#issuecomment-1315179508)
    this.appearance = '';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldAppearanceDirective_BaseFactory;
      return function TuiTextfieldAppearanceDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldAppearanceDirective_BaseFactory || (ɵTuiTextfieldAppearanceDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldAppearanceDirective)))(__ngFactoryType__ || TuiTextfieldAppearanceDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldAppearanceDirective,
      selectors: [["", "tuiTextfieldAppearance", ""]],
      inputs: {
        appearance: [0, "tuiTextfieldAppearance", "appearance"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TuiTextfieldAppearanceDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldAppearanceDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldAppearance]',
      providers: [tuiProvide(TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TuiTextfieldAppearanceDirective)]
    }]
  }], null, {
    appearance: [{
      type: Input,
      args: ['tuiTextfieldAppearance']
    }]
  });
})();
const TUI_TEXTFIELD_CLEANER = tuiCreateTokenFromFactory(() => new TuiTextfieldCleanerDirective());
class TuiTextfieldCleanerDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.cleaner = false;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldCleanerDirective_BaseFactory;
      return function TuiTextfieldCleanerDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldCleanerDirective_BaseFactory || (ɵTuiTextfieldCleanerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldCleanerDirective)))(__ngFactoryType__ || TuiTextfieldCleanerDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldCleanerDirective,
      selectors: [["", "tuiTextfieldCleaner", ""]],
      inputs: {
        cleaner: [0, "tuiTextfieldCleaner", "cleaner"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_CLEANER, TuiTextfieldCleanerDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldCleanerDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldCleaner]',
      providers: [tuiProvide(TUI_TEXTFIELD_CLEANER, TuiTextfieldCleanerDirective)]
    }]
  }], null, {
    cleaner: [{
      type: Input,
      args: ['tuiTextfieldCleaner']
    }]
  });
})();
const TUI_TEXTFIELD_CUSTOM_CONTENT = tuiCreateTokenFromFactory(() => new TuiTextfieldCustomContentDirective());
class TuiTextfieldCustomContentDirective extends AbstractTuiController {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldCustomContentDirective_BaseFactory;
      return function TuiTextfieldCustomContentDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldCustomContentDirective_BaseFactory || (ɵTuiTextfieldCustomContentDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldCustomContentDirective)))(__ngFactoryType__ || TuiTextfieldCustomContentDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldCustomContentDirective,
      selectors: [["", "tuiTextfieldCustomContent", ""]],
      inputs: {
        customContent: [0, "tuiTextfieldCustomContent", "customContent"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_CUSTOM_CONTENT, TuiTextfieldCustomContentDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldCustomContentDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldCustomContent]',
      providers: [tuiProvide(TUI_TEXTFIELD_CUSTOM_CONTENT, TuiTextfieldCustomContentDirective)]
    }]
  }], null, {
    customContent: [{
      type: Input,
      args: ['tuiTextfieldCustomContent']
    }]
  });
})();
const TUI_TEXTFIELD_FILLER = tuiCreateTokenFromFactory(() => new TuiTextfieldFillerDirective());
class TuiTextfieldFillerDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.filler = '';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldFillerDirective_BaseFactory;
      return function TuiTextfieldFillerDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldFillerDirective_BaseFactory || (ɵTuiTextfieldFillerDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldFillerDirective)))(__ngFactoryType__ || TuiTextfieldFillerDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldFillerDirective,
      selectors: [["", "tuiTextfieldFiller", ""]],
      inputs: {
        filler: [0, "tuiTextfieldFiller", "filler"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_FILLER, TuiTextfieldFillerDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldFillerDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldFiller]',
      providers: [tuiProvide(TUI_TEXTFIELD_FILLER, TuiTextfieldFillerDirective)]
    }]
  }], null, {
    filler: [{
      type: Input,
      args: ['tuiTextfieldFiller']
    }]
  });
})();
const TUI_TEXTFIELD_ICON = tuiCreateTokenFromFactory(() => new TuiTextfieldIconDirective());
class TuiTextfieldIconDirective extends AbstractTuiController {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldIconDirective_BaseFactory;
      return function TuiTextfieldIconDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldIconDirective_BaseFactory || (ɵTuiTextfieldIconDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldIconDirective)))(__ngFactoryType__ || TuiTextfieldIconDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldIconDirective,
      selectors: [["", "tuiTextfieldIcon", ""]],
      inputs: {
        icon: [0, "tuiTextfieldIcon", "icon"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_ICON, TuiTextfieldIconDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldIconDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldIcon]',
      providers: [tuiProvide(TUI_TEXTFIELD_ICON, TuiTextfieldIconDirective)]
    }]
  }], null, {
    icon: [{
      type: Input,
      args: ['tuiTextfieldIcon']
    }]
  });
})();
const TUI_TEXTFIELD_ICON_LEFT = tuiCreateTokenFromFactory(() => new TuiTextfieldIconLeftDirective());
class TuiTextfieldIconLeftDirective extends AbstractTuiController {
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldIconLeftDirective_BaseFactory;
      return function TuiTextfieldIconLeftDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldIconLeftDirective_BaseFactory || (ɵTuiTextfieldIconLeftDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldIconLeftDirective)))(__ngFactoryType__ || TuiTextfieldIconLeftDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldIconLeftDirective,
      selectors: [["", "tuiTextfieldIconLeft", ""]],
      inputs: {
        iconStart: [0, "tuiTextfieldIconLeft", "iconStart"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_ICON_LEFT, TuiTextfieldIconLeftDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldIconLeftDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldIconLeft]',
      providers: [tuiProvide(TUI_TEXTFIELD_ICON_LEFT, TuiTextfieldIconLeftDirective)]
    }]
  }], null, {
    iconStart: [{
      type: Input,
      args: ['tuiTextfieldIconLeft']
    }]
  });
})();
const TUI_TEXTFIELD_LABEL_OUTSIDE = tuiCreateTokenFromFactory(() => new TuiTextfieldLabelOutsideDirective());
class TuiTextfieldLabelOutsideDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.labelOutside = false;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldLabelOutsideDirective_BaseFactory;
      return function TuiTextfieldLabelOutsideDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldLabelOutsideDirective_BaseFactory || (ɵTuiTextfieldLabelOutsideDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldLabelOutsideDirective)))(__ngFactoryType__ || TuiTextfieldLabelOutsideDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldLabelOutsideDirective,
      selectors: [["", "tuiTextfieldLabelOutside", ""]],
      inputs: {
        labelOutside: [0, "tuiTextfieldLabelOutside", "labelOutside"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_LABEL_OUTSIDE, TuiTextfieldLabelOutsideDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldLabelOutsideDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldLabelOutside]',
      providers: [tuiProvide(TUI_TEXTFIELD_LABEL_OUTSIDE, TuiTextfieldLabelOutsideDirective)]
    }]
  }], null, {
    labelOutside: [{
      type: Input,
      args: ['tuiTextfieldLabelOutside']
    }]
  });
})();
const TUI_TEXTFIELD_POSTFIX = tuiCreateTokenFromFactory(() => new TuiTextfieldPostfixDirective());
class TuiTextfieldPostfixDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.postfix = '';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldPostfixDirective_BaseFactory;
      return function TuiTextfieldPostfixDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldPostfixDirective_BaseFactory || (ɵTuiTextfieldPostfixDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldPostfixDirective)))(__ngFactoryType__ || TuiTextfieldPostfixDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldPostfixDirective,
      selectors: [["", "tuiTextfieldPostfix", ""]],
      inputs: {
        postfix: [0, "tuiTextfieldPostfix", "postfix"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_POSTFIX, TuiTextfieldPostfixDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldPostfixDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldPostfix]',
      providers: [tuiProvide(TUI_TEXTFIELD_POSTFIX, TuiTextfieldPostfixDirective)]
    }]
  }], null, {
    postfix: [{
      type: Input,
      args: ['tuiTextfieldPostfix']
    }]
  });
})();
const TUI_TEXTFIELD_PREFIX = tuiCreateTokenFromFactory(() => new TuiTextfieldPrefixDirective());
class TuiTextfieldPrefixDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.prefix = '';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldPrefixDirective_BaseFactory;
      return function TuiTextfieldPrefixDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldPrefixDirective_BaseFactory || (ɵTuiTextfieldPrefixDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldPrefixDirective)))(__ngFactoryType__ || TuiTextfieldPrefixDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldPrefixDirective,
      selectors: [["", "tuiTextfieldPrefix", ""]],
      inputs: {
        prefix: [0, "tuiTextfieldPrefix", "prefix"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_PREFIX, TuiTextfieldPrefixDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldPrefixDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldPrefix]',
      providers: [tuiProvide(TUI_TEXTFIELD_PREFIX, TuiTextfieldPrefixDirective)]
    }]
  }], null, {
    prefix: [{
      type: Input,
      args: ['tuiTextfieldPrefix']
    }]
  });
})();
const TUI_TEXTFIELD_SIZE = tuiCreateTokenFromFactory(() => new TuiTextfieldSizeDirective());
class TuiTextfieldSizeDirective extends AbstractTuiController {
  constructor() {
    super(...arguments);
    this.size = 'l';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTextfieldSizeDirective_BaseFactory;
      return function TuiTextfieldSizeDirective_Factory(__ngFactoryType__) {
        return (ɵTuiTextfieldSizeDirective_BaseFactory || (ɵTuiTextfieldSizeDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTextfieldSizeDirective)))(__ngFactoryType__ || TuiTextfieldSizeDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiTextfieldSizeDirective,
      selectors: [["", "tuiTextfieldSize", ""]],
      inputs: {
        size: [0, "tuiTextfieldSize", "size"]
      },
      standalone: false,
      features: [i0.ɵɵProvidersFeature([tuiProvide(TUI_TEXTFIELD_SIZE, TuiTextfieldSizeDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldSizeDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: '[tuiTextfieldSize]',
      providers: [tuiProvide(TUI_TEXTFIELD_SIZE, TuiTextfieldSizeDirective)]
    }]
  }], null, {
    size: [{
      type: Input,
      args: ['tuiTextfieldSize']
    }]
  });
})();
class TuiTextfieldControllerModule {
  static {
    this.ɵfac = function TuiTextfieldControllerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTextfieldControllerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiTextfieldControllerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTextfieldControllerModule, [{
    type: NgModule,
    args: [{
      imports: [PolymorpheusOutlet, PolymorpheusTemplate],
      declarations: [TuiTextfieldAppearanceDirective, TuiTextfieldCleanerDirective, TuiTextfieldCustomContentDirective, TuiTextfieldLabelOutsideDirective, TuiTextfieldSizeDirective, TuiTextfieldIconDirective, TuiTextfieldIconLeftDirective, TuiTextfieldPrefixDirective, TuiTextfieldPostfixDirective, TuiTextfieldFillerDirective],
      exports: [TuiTextfieldAppearanceDirective, TuiTextfieldCleanerDirective, TuiTextfieldCustomContentDirective, TuiTextfieldLabelOutsideDirective, TuiTextfieldSizeDirective, TuiTextfieldIconDirective, TuiTextfieldIconLeftDirective, TuiTextfieldPrefixDirective, TuiTextfieldPostfixDirective, TuiTextfieldFillerDirective, PolymorpheusOutlet, PolymorpheusTemplate]
    }]
  }], null, null);
})();
const TUI_TEXTFIELD_WATCHED_CONTROLLER = tuiCreateToken();
const TEXTFIELD_CONTROLLER_PROVIDER = [{
  provide: TUI_TEXTFIELD_WATCHED_CONTROLLER,
  deps: [ChangeDetectorRef, TUI_TEXTFIELD_OPTIONS, TUI_TEXTFIELD_APPEARANCE, TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_CUSTOM_CONTENT, TUI_TEXTFIELD_ICON, TUI_TEXTFIELD_ICON_LEFT, TUI_TEXTFIELD_LABEL_OUTSIDE, TUI_TEXTFIELD_SIZE, TUI_TEXTFIELD_PREFIX, TUI_TEXTFIELD_POSTFIX, TUI_TEXTFIELD_FILLER],
  useFactory: (cdr, options, legacyAppearance, ...controllers) => {
    const change$ = merge(...controllers.map(({
      change$
    }) => change$ || NEVER)).pipe(tuiWatch(cdr), takeUntilDestroyed());
    change$.subscribe();
    return new TuiTextfieldController(change$, options, legacyAppearance, ...controllers);
  }
}];

/**
 * Generated bundle index. Do not edit.
 */

export { TEXTFIELD_CONTROLLER_PROVIDER, TUI_TEXTFIELD_APPEARANCE_DIRECTIVE, TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_CUSTOM_CONTENT, TUI_TEXTFIELD_DEFAULT_OPTIONS, TUI_TEXTFIELD_FILLER, TUI_TEXTFIELD_ICON, TUI_TEXTFIELD_ICON_LEFT, TUI_TEXTFIELD_LABEL_OUTSIDE, TUI_TEXTFIELD_OPTIONS, TUI_TEXTFIELD_POSTFIX, TUI_TEXTFIELD_PREFIX, TUI_TEXTFIELD_SIZE, TUI_TEXTFIELD_WATCHED_CONTROLLER, TuiTextfieldAppearanceDirective, TuiTextfieldCleanerDirective, TuiTextfieldController, TuiTextfieldControllerModule, TuiTextfieldCustomContentDirective, TuiTextfieldFillerDirective, TuiTextfieldIconDirective, TuiTextfieldIconLeftDirective, TuiTextfieldLabelOutsideDirective, TuiTextfieldPostfixDirective, TuiTextfieldPrefixDirective, TuiTextfieldSizeDirective, tuiTextfieldOptionsProvider };
