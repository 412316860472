import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { inject, NgZone, Directive, Output, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { TUI_ACTIVE_ELEMENT } from '@taiga-ui/cdk/tokens';
import { tuiInjectElement, tuiArrayRemove, tuiPure } from '@taiga-ui/cdk/utils';
import { map, startWith, distinctUntilChanged, skip, tap } from 'rxjs';
class TuiActiveZone {
  constructor() {
    this.control = inject(NgControl, {
      self: true,
      optional: true
    });
    this.active$ = inject(TUI_ACTIVE_ELEMENT);
    this.zone = inject(NgZone);
    this.el = tuiInjectElement();
    this.tuiActiveZoneParent = null;
    this.subActiveZones = [];
    this.directParentActiveZone = inject(TuiActiveZone, {
      skipSelf: true,
      optional: true
    });
    this.tuiActiveZoneChange = this.active$.pipe(map(element => !!element && this.contains(element)), startWith(false), distinctUntilChanged(), skip(1), tap(active => {
      if (!active && typeof this.control?.valueAccessor.onTouched === 'function') {
        this.control.valueAccessor.onTouched();
      }
    }), tuiZoneOptimized(this.zone));
    this.directParentActiveZone?.addSubActiveZone(this);
  }
  set tuiActiveZoneParentSetter(zone) {
    this.setZone(zone);
  }
  ngOnDestroy() {
    this.directParentActiveZone?.removeSubActiveZone(this);
    this.tuiActiveZoneParent?.removeSubActiveZone(this);
  }
  contains(node) {
    return this.el.contains(node) || this.subActiveZones.some((item, index, array) => array.indexOf(item) === index && item.contains(node));
  }
  setZone(zone) {
    this.tuiActiveZoneParent?.removeSubActiveZone(this);
    zone?.addSubActiveZone(this);
    this.tuiActiveZoneParent = zone;
  }
  addSubActiveZone(activeZone) {
    this.subActiveZones = [...this.subActiveZones, activeZone];
  }
  removeSubActiveZone(activeZone) {
    this.subActiveZones = tuiArrayRemove(this.subActiveZones, this.subActiveZones.indexOf(activeZone));
  }
  static {
    this.ɵfac = function TuiActiveZone_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiActiveZone)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiActiveZone,
      selectors: [["", "tuiActiveZone", "", 5, "ng-container"], ["", "tuiActiveZoneChange", "", 5, "ng-container"], ["", "tuiActiveZoneParent", "", 5, "ng-container"]],
      hostBindings: function TuiActiveZone_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown.silent", function TuiActiveZone_mousedown_silent_HostBindingHandler() {
            return 0;
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        tuiActiveZoneParentSetter: [0, "tuiActiveZoneParent", "tuiActiveZoneParentSetter"]
      },
      outputs: {
        tuiActiveZoneChange: "tuiActiveZoneChange"
      },
      exportAs: ["tuiActiveZone"]
    });
  }
}
__decorate([tuiPure], TuiActiveZone.prototype, "setZone", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiActiveZone, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiActiveZone]:not(ng-container), [tuiActiveZoneChange]:not(ng-container), [tuiActiveZoneParent]:not(ng-container)',
      exportAs: 'tuiActiveZone',
      host: {
        '(document:mousedown.silent)': '(0)'
      }
    }]
  }], function () {
    return [];
  }, {
    tuiActiveZoneChange: [{
      type: Output
    }],
    tuiActiveZoneParentSetter: [{
      type: Input,
      args: ['tuiActiveZoneParent']
    }],
    setZone: []
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiActiveZone };
