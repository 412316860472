import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { AuthService } from '../../auth.service';

import { AccountService } from '../../account.service';
import { environment } from '../../../../environments/environment';
import { TuiInputModule } from '@taiga-ui/legacy';
import { TuiButton, TuiTextfield } from '@taiga-ui/core';


@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css'],
    imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, TuiInputModule, TuiTextfield, TuiButton]
})
export class ForgotPasswordComponent implements OnInit {

  private readonly accountService: AccountService = inject(AccountService);
  forgotPasswordForm!: UntypedFormGroup;
  error: string | null = null;
  requestSent = false;
  version:string = environment.version;;

  constructor() {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required,Validators.email]),
    });

    this.forgotPasswordForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });
  }

  onSubmit(): void {
    let email = this.forgotPasswordForm.get('email')?.value as string;
    this.accountService.forgotPassword(email.toLowerCase()).subscribe({
      error: error => this.error = error,
      next: value =>  {this.requestSent = true;}
    })
  }


}
