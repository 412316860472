import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, map, of, switchMap, tap, throwError } from 'rxjs';
import { authResponseDTO } from './models/authResponseDTO';
import { Router } from '@angular/router';
import { getErrorMessage } from './models/errorMap';
import { BaseEndpointService } from '../shared/interfaces/IEndpoint';
import { environment } from '../../environments/environment';

export const status = {
  successful: 'SUCCESSFUL',
  failed: 'FAILED',
  notStarted: 'NOT STARTED',
  refreshing: 'IN PROGRESS'
} as const;

type StatusValues = typeof status[keyof typeof status];


interface RefreshResult {
  isRefreshing: boolean;
  status: StatusValues;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseEndpointService<'auth'> {
  private readonly router = inject(Router);
  public readonly isLoggedIn$ = new BehaviorSubject(false);

  public readonly isRefreshingToken$ = new BehaviorSubject<RefreshResult>({isRefreshing: false, status:'NOT STARTED'});

  constructor() {

    super({routeTemplate: 'auth'})
  }

  public get isLoggedIn() {
    return this.isLoggedIn$.value;
  }

  public set isLoggedIn(value: boolean) {
    this.isLoggedIn$.next(value);
  }

  isAuthenticated(): Observable<boolean> {
    if(!environment.production && (location.hostname == '192.168.0.136' || location.hostname == '10.2.2.103' || location.hostname == 'localhost')) {
      return of(true);
    }

    return this.http.get<{ isAuthenticated: boolean }>(this.endpoint + '/verify').pipe(
      map(response => response.isAuthenticated),
      tap(isAuthenticated => {

        this.isLoggedIn$.next(isAuthenticated)
      })
    );
  }


  login(model: {email:string, password:string}) {
    return this.http
      .post<authResponseDTO>(this.endpoint + '/login', {
      ...model
      })
      .pipe(
        tap((result) => {
          this.isLoggedIn$.next(true);
        }),
        catchError(error => this.handleError(error))
      );
  }

  refreshToken() {


    this.isRefreshingToken$.next({isRefreshing: true, status: 'IN PROGRESS'});

    return this.http
      .post<authResponseDTO>(this.endpoint + `/token/refresh`,{})
      .pipe(
        tap((result) => {

          this.isRefreshingToken$.next({isRefreshing: false, status:'SUCCESSFUL'});

          this.isLoggedIn$.next(true);
        }
       ),
       catchError(error => {

        this.isRefreshingToken$.next({isRefreshing: false, status:'FAILED'});
        return this.logout();
      }),
      finalize(() =>  this.isRefreshingToken$.next({isRefreshing: false, status:'NOT STARTED'}))
      );
  }

  logout()
  {
    this.isLoggedIn$.next(false);
    this.router.navigateByUrl('/login');

    return this.http.get(this.endpoint + '/logout');
  }

  handleError(errorRes:HttpErrorResponse)
  {
    let errorMessage = 'An unknown error occurred!'

    const errorDetail = errorRes.error != null ? errorRes.error.replaceAll(" ", "").split(":")[1] : null

    if (errorDetail) {
      errorMessage = getErrorMessage(errorDetail)
    }

    return throwError(() => new Error(errorMessage));
  }

}
