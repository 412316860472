import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { TuiHintOptionsDirective } from '@taiga-ui/core/directives/hint';
import * as i1 from '@taiga-ui/core/components/icon';
import { TuiIcon } from '@taiga-ui/core/components/icon';
import * as i2 from '@taiga-ui/kit/directives/tooltip';
import { TuiTooltip } from '@taiga-ui/kit/directives/tooltip';

/**
 * @deprecated: drop in v5.0 use {@link TuiTooltip}
 * https://taiga-ui.dev/components/tooltip
 */
class TuiTooltipComponent extends TuiHintOptionsDirective {
  constructor() {
    super(...arguments);
    this.describeId = '';
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiTooltipComponent_BaseFactory;
      return function TuiTooltipComponent_Factory(__ngFactoryType__) {
        return (ɵTuiTooltipComponent_BaseFactory || (ɵTuiTooltipComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiTooltipComponent)))(__ngFactoryType__ || TuiTooltipComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiTooltipComponent,
      selectors: [["tui-tooltip"]],
      inputs: {
        content: "content",
        direction: "direction",
        appearance: "appearance",
        showDelay: "showDelay",
        hideDelay: "hideDelay",
        describeId: "describeId",
        context: "context"
      },
      standalone: false,
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 1,
      vars: 7,
      consts: [["automation-id", "tui-tooltip__icon", 3, "tuiHintAppearance", "tuiHintContext", "tuiHintDirection", "tuiHintHideDelay", "tuiHintShowDelay", "tuiTooltip", "tuiTooltipDescribe"]],
      template: function TuiTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "tui-icon", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("tuiHintAppearance", ctx.appearance || "")("tuiHintContext", ctx.context)("tuiHintDirection", ctx.direction)("tuiHintHideDelay", ctx.hideDelay)("tuiHintShowDelay", ctx.showDelay)("tuiTooltip", ctx.content)("tuiTooltipDescribe", ctx.describeId);
        }
      },
      dependencies: [i1.TuiIcon, i2.TuiTooltip],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      inputs: ['content', 'direction', 'appearance', 'showDelay', 'hideDelay'],
      template: "<tui-icon\n    automation-id=\"tui-tooltip__icon\"\n    [tuiHintAppearance]=\"appearance || ''\"\n    [tuiHintContext]=\"context\"\n    [tuiHintDirection]=\"direction\"\n    [tuiHintHideDelay]=\"hideDelay\"\n    [tuiHintShowDelay]=\"showDelay\"\n    [tuiTooltip]=\"content\"\n    [tuiTooltipDescribe]=\"describeId\"\n/>\n"
    }]
  }], null, {
    describeId: [{
      type: Input
    }],
    context: [{
      type: Input
    }]
  });
})();

/**
 * @deprecated: drop in v5.0 use {@link TuiTooltip}
 * https://taiga-ui.dev/components/tooltip
 */
class TuiTooltipModule {
  static {
    this.ɵfac = function TuiTooltipModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiTooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiTooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TuiIcon]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipModule, [{
    type: NgModule,
    args: [{
      imports: [TuiIcon, TuiTooltip],
      declarations: [TuiTooltipComponent],
      exports: [TuiTooltipComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiTooltipComponent, TuiTooltipModule };
