import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Directive } from '@angular/core';
import { tuiInjectElement, tuiIsHTMLElement, tuiContainsOrAfter } from '@taiga-ui/cdk/utils/dom';
import { tuiGetNativeFocused, tuiBlurNativeFocused, tuiGetClosestFocusable } from '@taiga-ui/cdk/utils/focus';
class TuiFocusTrap {
  constructor() {
    this.doc = inject(DOCUMENT);
    this.el = tuiInjectElement();
    this.activeElement = null;
    this.initialized = false;
    /**
     * This would cause currently focused element to lose focus,
     * but it might cause ExpressionChanged error due to potential HostBinding.
     * Microtask keeps it in the same frame but allows change detection to run
     */
    Promise.resolve().then(() => {
      /**
       * The same event can synchronously close already opened focus trap and open another one.
       * All focus traps have microtask inside its `ngOnDestroy` –
       * they should be resolved before enabling of new focus trap.
       * Don't enable any new event listeners before `initialized` equals to `true`!
       */
      this.initialized = true;
      this.activeElement = tuiGetNativeFocused(this.doc);
      this.el.focus();
    });
  }
  ngOnDestroy() {
    tuiBlurNativeFocused(this.doc);
    /**
     * HostListeners are triggered even after ngOnDestroy
     * {@link https://github.com/angular/angular/issues/38100}
     * so we need to delay it but stay in the same sync cycle,
     * therefore using Promise instead of setTimeout
     */
    Promise.resolve().then(() => {
      if (tuiIsHTMLElement(this.activeElement)) {
        this.activeElement.focus();
      }
    });
  }
  onFocusIn(node) {
    const firstElementChild = this.el.firstElementChild;
    if (!tuiContainsOrAfter(this.el, node) && firstElementChild) {
      tuiGetClosestFocusable({
        initial: firstElementChild,
        root: this.el
      })?.focus();
    }
  }
  static {
    this.ɵfac = function TuiFocusTrap_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiFocusTrap)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiFocusTrap,
      selectors: [["", "tuiFocusTrap", ""]],
      hostAttrs: ["tabIndex", "0"],
      hostBindings: function TuiFocusTrap_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("focusin.silent", function TuiFocusTrap_focusin_silent_HostBindingHandler($event) {
            return ctx.initialized && ctx.onFocusIn($event.target);
          }, false, i0.ɵɵresolveWindow);
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusTrap, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[tuiFocusTrap]',
      host: {
        tabIndex: '0',
        '(window:focusin.silent)': 'initialized && onFocusIn($event.target)'
      }
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFocusTrap };
